body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.dotted_style {
  border-style: dashed;
  border-left:#fff ;
  border-top:#fff ;
  border-bottom:#fff ;
}
 .border_solid {
   border-style: solid;
 }
 .border_solid_bottom{
 border-bottom-width: 0.3ch;
 border-top-width: 0.08ch;
  border-style: solid;
  border-left:#fff ;
  border-right:#fff ;
}
.border_solid_bottom_blank{
  border-bottom-width: 0.3ch;
   border-style: solid;
   border-left:#fff ;
   border-right:#fff ;
   border-top:#fff ;

 }

 .table_height{
overflow: hidden;
height:9px;
white-space: nowrap;
}
