.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.stickyTable {
  position: sticky;
  left: 0;
  border-width: 0;
  border-right-width: 1;
  border-bottom-width: 1;
  border-color: #dcdcdc;
  border-style: solid;
  white-space: nowrap;
  z-index: 3 !important;
}
.buttonHover {
  display: none;
  /* display: flex;
  flex-direction: column;
  width: "100%";
  height: "100%";
  position: "absolute";
  top: 0;
  right: 0; */
}
.itemHover:hover + .buttonHover {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  background-color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 3.9em;
  height: 3.9em;
  border: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}
.loadermap {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(52% - 4em);
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid rgba(0, 0, 0, 0.2);
  border-left: 0.4em solid #f5f1f1;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.table-format {
  border: 1px solid black;
}

.table-format-table {
  width: 100%;
  border-collapse: collapse;
}
#mytable {
  border-collapse: collapse;
}
#mytable th,
#mytable td {
  border: 1px solid black;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
#hover_tr:hover {
  background-color: #d1d4db;
}
